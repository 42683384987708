import axios from 'axios'

var API_URL = process.env.VUE_APP_BACKEND_URL
var MOBILE_API_URL = process.env.VUE_APP_MOBILE_BACKEND_V2

export class DataService {
  getProject(project) {
    const url = `${API_URL}/mobile/dashboard/getProject`
    axios.defaults.headers.common['Authorization'] = window.$cookies.get('izcToken')

    return axios.post(url, project).then(res => res.data)
  }
 
  getProjects(params) {
    const url = `${API_URL}/mobile/dashboard/getProjects`
    axios.defaults.headers.common['Authorization'] = window.$cookies.get('izcToken')

    return axios.post(url, params).then(res => res.data)
  }

  getUserActivityData(activityDataDto) {
    const url = `${API_URL}/mobile/responses/getUserActivityData`
    axios.defaults.headers.common['Authorization'] = window.$cookies.get('izcToken')
    
    return axios.post(url, activityDataDto).then(res => res.data)
  }

  resetPassword(data) {
    const url = `${MOBILE_API_URL}/api/user/reset-password`
    return axios.post(url, data).then(res => res.data)
  }
}
