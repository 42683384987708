<template>
  <div>
    <NavbarGuest />
    <v-app-bar dark color="#19647E">
      <v-icon large class="mr-3">text_snippet</v-icon>
      <v-toolbar-title>Set Password</v-toolbar-title>
    </v-app-bar>
    <v-card>
      <v-container>

        <v-alert type="success" v-if="passwordResetSuccess">Password reset is successful! Please login from your mobile app.</v-alert>

        <v-form ref="form">
          <v-text-field
            v-model="profile.password"
            label="New Password"
            v-validate="'required|min:8'"
            required
            data-vv-name="New Password"
            :error-messages="errors.collect('New Password')"
            ref="newPassword"
            :type="showNewPassword ? 'text' : 'password'"
            :append-icon="showNewPassword ? 'visibility' : 'visibility_off'"
            @click:append="showNewPassword = !showNewPassword"
          ></v-text-field>

          <v-text-field
            v-model="profile.confirmNewPassword"
            label="Confirm New Password"
            v-validate="'required|min:8|confirmed:newPassword'"
            required
            data-vv-name="Confirm New Password"
            :error-messages="errors.collect('Confirm New Password')"
            ref="confirmPassword"
            :type="showConfirmPassword ? 'text' : 'password'"
            :append-icon="showConfirmPassword ? 'visibility' : 'visibility_off'"
            @click:append="showConfirmPassword = !showConfirmPassword"
          ></v-text-field>
          
          <v-btn dark color="#19647E" @click.prevent="setPassword()">
            Update Password
          </v-btn>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import NavbarGuest from "../components/NavbarGuest.vue";
import { DataService as MobileDataService } from "./../services/MobileDataService";
const mobileDataService = new MobileDataService();

export default {
  components: {
    NavbarGuest,
  },
  data() {
    return {
      showNewPassword: false,
      showConfirmPassword: false,
      profile: {},
      passwordResetSuccess: false,
    };
  },
  methods: {
    async setPassword() {
      let data = {
        password: this.profile.password,
        confirmNewPassword: this.profile.confirmNewPassword,
        token: this.$route.query.token,
      };
      let isValid = await this.$validator.validateAll();
      if (isValid) {
        try {
          const result = await mobileDataService.resetPassword(data);
          if (result && result.isSuccess) {
            this.passwordResetSuccess = true;
            this.profile = {};
            this.$router.replace({ query: null });
          } else {
            console.error('Password reset failed', result);
          }
        } catch (error) {
          console.error('An error occurred during password reset', error);
        }
      }
    },
  },
};
</script>
